.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::selection {
  background: #b49f6b;
  color: #202020;
}
* {
  -webkit-tap-highlight-color: rgba(197, 181, 141, 0.1);
  box-sizing: border-box;
}
@media (min-width: 61.875rem) {
  * {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
main {
  display: block;
}
html {
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #202020;
  font-family: "Fira Sans", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 2.25;
  margin: 0 auto;
  max-width: 160rem;
  overflow-x: hidden;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
.style_h1,
.style_h2,
.style_h3,
.style_h4,
.style_h5,
.style_h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1.875rem + 1.75
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.875rem;
  color: #c5b58d;
  font-family: "Playfair Display", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
  text-align: center;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.875rem + 1.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 3.625rem;
  }
}
h2,
.style_h2 {
  letter-spacing: calc((1.5rem + 0.875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.5rem;
  color: #000000;
  font-family: "Playfair Display", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.5rem + 0.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 2.375rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1.125rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #000000;
  font-family: "Playfair Display", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1.125rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 1.75rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((1.125rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #000000;
  font-family: "Playfair Display", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(1.125rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4,
  .style_h4 {
    font-size: 1.75rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((1.125rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #000000;
  font-family: "Playfair Display", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(1.125rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 1.75rem;
  }
}
h6,
.style_h6 {
  letter-spacing: calc((1.125rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #000000;
  font-family: "Playfair Display", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h6,
  .style_h6 {
    font-size: calc(1.125rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h6,
  .style_h6 {
    font-size: 1.75rem;
  }
}
p {
  margin: 0 0 1.125em;
}
a {
  color: #c5b58d;
  text-decoration: none;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #202020;
  outline: 0;
}
a:focus {
  color: #202020;
  outline: 0.125rem solid rgba(197, 181, 141, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  color: #202020;
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #202020;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #c5b58d;
  display: inline-block;
  margin-bottom: 0.5625em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #202020;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.125rem solid rgba(197, 181, 141, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 500;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 1.125em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 2.25em;
  overflow-y: auto;
}
* + .table {
  margin-top: 2.25em;
}
table {
  border-bottom: 0.0625rem solid #dfdfdf;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #dfdfdf;
  padding: 0.75em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #f4f4f4;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 1.125em;
  padding: 0;
}
ol > li {
  padding-left: 2.25em;
  position: relative;
}
ol > li:before {
  color: #c5b58d;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 2.25;
  position: absolute;
  text-align: right;
  width: 1.6875em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg") !important;
  margin: 0 0 1.125em;
  padding-left: 1.6875em;
}
ul > li {
  padding-left: 0.5625em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #c5b58d;
  margin: 1.125em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #c5b58d;
  font-weight: 500;
  padding: 1.125em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 1.125em 1.125em 1.125em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.0625rem solid #c5b58d;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 1.125em 0.75em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 2.25em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 2.25em;
}
figure.floatright {
  float: right;
  margin-left: 2.25em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.5625em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 400;
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1rem;
  }
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #202020;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #c5b58d inset, 0 0 0 0.1875rem rgba(197, 181, 141, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #606060;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #606060;
  -ms-user-select: none;
      user-select: none;
}
::-ms-input-placeholder {
  color: #606060;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #606060;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #c5b58d;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #c5b58d;
  color: #fff;
  display: none;
  margin-right: 0.5625em;
  padding: 0 0.5625em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.125rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #000000;
  font-family: "Playfair Display", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 1.125em;
  padding: 1.125em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.125rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.75rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.5625em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #dfdfdf;
  border-radius: 0;
  color: #202020;
  margin: 0 0 1.5em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #c5b58d;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #c5b58d;
  box-shadow: 0 0 0 0.1875rem rgba(197, 181, 141, 0.2);
  color: #202020;
}
.input:focus,
.textarea:focus {
  border-color: #c5b58d;
  box-shadow: 0 0 0 0.1875rem rgba(197, 181, 141, 0.2);
  color: #202020;
}
.input {
  padding: 0 1.125em;
  -webkit-transition: border 300ms, box-shadow 300ms;
  transition: border 300ms, box-shadow 300ms;
  height: 2.75rem;
}
@media (min-width: 20rem) {
  .input {
    height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 2.75rem;
  }
}
.textarea {
  line-height: 2.25;
  overflow: hidden;
  padding: 1.125em;
  resize: none;
  -webkit-transition: border 300ms, box-shadow 300ms, height 300ms;
  transition: border 300ms, box-shadow 300ms, height 300ms;
}
.control {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  margin: 0.5625em 1.125em 0.5625em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #c5b58d;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #c5b58d;
  box-shadow: 0 0 0 0.1875rem rgba(197, 181, 141, 0.2);
  color: #202020;
}
.control input:focus ~ .indicator {
  border-color: #c5b58d;
  box-shadow: 0 0 0 0.1875rem rgba(197, 181, 141, 0.2);
  color: #202020;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 1.125em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #dfdfdf;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #202020;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  align-content: center;
  background: #fff;
  border: 0.0625rem solid #dfdfdf;
  border-radius: 0;
  color: #202020;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  margin: 0 0 1.5em;
  padding: 0 1.125em;
  position: relative;
  -webkit-transition: border 300ms, box-shadow 300ms;
  transition: border 300ms, box-shadow 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #202020;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 1.125em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #c5b58d;
}
.select.error.focus {
  border-color: #c5b58d;
  box-shadow: 0 0 0 0.1875rem rgba(197, 181, 141, 0.2);
  color: #202020;
}
.select.focus {
  border-color: #c5b58d;
  box-shadow: 0 0 0 0.1875rem rgba(197, 181, 141, 0.2);
  color: #202020;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #202020;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select {
    min-height: calc(2.625rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 2.625rem;
  }
}
select option {
  min-height: 2.625rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.625rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 2.625rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #202020;
}
select:focus::-ms-value {
  background: none;
  color: #202020;
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(197, 181, 141, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(197, 181, 141, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(197, 181, 141, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(197, 181, 141, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .background_wedding {
    background-color: #eae7e0;
  }
  .btn_special {
    border-style: dashed;
    position: fixed;
    bottom: 8rem;
    right: 6rem;
    z-index: 100000;
    border: 1rem solid black inherit;
    border-radius: 1;
    padding: 0 2.25em;
    background: #c5b58d;
    border: 0.0625rem solid #c5b58d;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 2.75rem;
    -webkit-box-pack: center;
    justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms,border 300ms,color 300ms;
    transition: background 300ms,border 300ms,color 300ms;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .event_overview {
    margin-top: 2.25em;
    margin-bottom: 4.5em;
  }
  .event_overview .event {
    margin: 0 0 4.5em;
  }
  .event_overview .event .title {
    padding-top: 0;
  }
  .event_overview .event .read_more {
    margin-bottom: 8px;
  }
  .event_overview .event .add_to_calendar,
  .event_overview .event .read_more {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    text-decoration: none;
  }
  .event_overview .event .add_to_calendar:hover,
  .event_overview .event .read_more:hover,
  .event_overview .event .add_to_calendar:focus,
  .event_overview .event .read_more:focus {
    color: #c5b58d;
  }
  .event_overview .event .add_to_calendar:hover .icon,
  .event_overview .event .read_more:hover .icon,
  .event_overview .event .add_to_calendar:focus .icon,
  .event_overview .event .read_more:focus .icon {
    border: 0.125rem solid #c5b58d;
    -webkit-transition: border 200ms;
    transition: border 200ms;
  }
  .event_overview .event .add_to_calendar .icon,
  .event_overview .event .read_more .icon {
    border: 0.125rem solid #dfdfdf;
    display: inline-block;
    fill: #dfdfdf;
    padding: 0.25em 0.75em;
    -webkit-transition: border 200ms;
    transition: border 200ms;
  }
  .event_overview .event .add_to_calendar .icon svg,
  .event_overview .event .read_more .icon svg {
    height: 24px;
    margin-top: 0.5625em;
    width: 24px;
  }
  .event_overview .event .add_to_calendar .text,
  .event_overview .event .read_more .text {
    padding-left: 1.125em;
  }
  .event_overview .event .date {
    -webkit-box-align: center;
            align-items: center;
    background-color: #c5b58d;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-wrap: wrap;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
  }
  .event_overview .event .date .day,
  .event_overview .event .date .month,
  .event_overview .event .date .year {
    display: block;
    width: 100%;
    text-align: center;
  }
  .event_overview .event .date .day {
    align-self: flex-end;
    font-size: 350%;
    line-height: 1.3;
  }
  .event_overview .event .date .month {
    align-self: flex-start;
    padding-bottom: 0.5625em;
  }
  h2#reservation {
    text-align: center;
  }
  h2#getDate {
    text-align: center;
  }
  h3#getLocation {
    text-align: center;
  }
  a#moreinformation {
    text-align: center;
  }
  h3.title {
    text-align: center;
  }
  p.text_center {
    text-align: center;
  }
  h2#accordion_button {
    text-align: center;
  }
  .big_headline {
    width: 100%;
    text-align: center;
  }
  .thumbnail {
    display: block;
    margin: auto;
  }
  .formular_short {
    background: #c5b58d;
    margin: 2.25em 0;
    padding: 0 0.9375rem;
    position: relative;
  }
  .formular_short .title_wrapper {
    margin-bottom: 1.125em;
  }
  .formular_short h2,
  .formular_short h3 {
    text-align: center;
  }
  .formular_short h2 {
    margin: 0 0 0.25rem 0;
  }
  .formular_short .subline,
  .formular_short .text {
    margin: 0 auto;
    width: 100%;
  }
  .formular_short .img_wrapper {
    display: none;
  }
  .formular_short fieldset {
    margin: 0 auto;
    max-width: 30rem;
  }
  .formular_short fieldset .checkbox {
    margin: 0;
  }
  .formular_short fieldset .indicator {
    border: 0;
  }
  .formular_short fieldset .indicator .icon {
    height: 1.375rem;
    width: 1.375rem;
  }
  .formular_short .button_wrapper {
    padding: 1.125em 0;
    text-align: center;
  }
  .formular_short .button {
    background: #f3f0e8;
    border: 0.0625rem solid #f3f0e8;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .formular_short .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .formular_short .button .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .formular_short .button .text {
    font-weight: 400;
    padding: 0.5625em 0;
    white-space: nowrap;
  }
  .formular_short .button .icon + .text {
    padding-left: 0.75em;
  }
  .formular_short .button:focus {
    background: #000000;
    border-color: #000000;
    color: #fff;
  }
  .formular_short .button:focus .icon {
    fill: #fff;
  }
  .formular_short .button:hover {
    background: #000000;
    border-color: #000000;
    color: #fff;
  }
  .formular_short .button:hover .icon {
    fill: #fff;
  }
  .formular_short .button:active,
  .formular_short .button.active {
    background: #ab945a;
    border: 0.0625rem solid #ab945a;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .formular_short .button:active > span,
  .formular_short .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .formular_short .button:active .icon,
  .formular_short .button.active .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .formular_short .button:active .text,
  .formular_short .button.active .text {
    font-weight: 400;
    padding: 0.5625em 0;
    white-space: nowrap;
  }
  .formular_short .button:active .icon + .text,
  .formular_short .button.active .icon + .text {
    padding-left: 0.75em;
  }
  .img_with_text {
    margin: 2.25em 0;
    position: relative;
  }
  .img_with_text .title_wrapper {
    margin-bottom: 1.125em;
  }
  .img_with_text h2,
  .img_with_text h3 {
    text-align: center;
  }
  .img_with_text h2 {
    margin: 0 0 0.25rem 0;
  }
  .img_with_text .subline,
  .img_with_text .text {
    margin: 0 auto;
    max-width: 30rem;
    width: 100%;
  }
  .img_with_text .text {
    text-align: center;
  }
  .img_with_text .btn_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0 auto;
  }
  .img_with_text .img_wrapper {
    line-height: 0;
    margin-bottom: 0.5625em;
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }
  .img_with_text.dark:before {
    background: #c5b58d;
    bottom: 0;
    content: "";
    left: -6249.9375rem;
    position: absolute;
    right: -6249.9375rem;
    top: 0;
    z-index: -1;
  }
  .img_with_text.dark .button {
    background: #f3f0e8;
    border: 0.0625rem solid #f3f0e8;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-bottom: 2.25em;
  }
  .img_with_text.dark .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .img_with_text.dark .button .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .img_with_text.dark .button .text {
    font-weight: 400;
    padding: 0.5625em 0;
    white-space: nowrap;
  }
  .img_with_text.dark .button .icon + .text {
    padding-left: 0.75em;
  }
  .img_with_text.dark .button:focus {
    background: #000000;
    border-color: #000000;
    color: #fff;
  }
  .img_with_text.dark .button:focus .icon {
    fill: #fff;
  }
  .img_with_text.dark .button:hover {
    background: #000000;
    border-color: #000000;
    color: #fff;
  }
  .img_with_text.dark .button:hover .icon {
    fill: #fff;
  }
  .img_with_text.dark .button:active,
  .img_with_text.dark .button.active {
    background: #ab945a;
    border: 0.0625rem solid #ab945a;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .img_with_text.dark .button:active > span,
  .img_with_text.dark .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .img_with_text.dark .button:active .icon,
  .img_with_text.dark .button.active .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .img_with_text.dark .button:active .text,
  .img_with_text.dark .button.active .text {
    font-weight: 400;
    padding: 0.5625em 0;
    white-space: nowrap;
  }
  .img_with_text.dark .button:active .icon + .text,
  .img_with_text.dark .button.active .icon + .text {
    padding-left: 0.75em;
  }
  .img_with_text.dark .title_wrapper h2 {
    padding: 0;
  }
  .img_with_text.dark .subline p:after,
  .img_with_text.dark .subline p:before {
    background-image: url("../images/star_dark.svg");
  }
  .img_with_text.dark .img_wrapper {
    margin: 1.125em auto;
    padding: 0.9375rem;
  }
  .page_slide_in {
    background-color: #fff;
    border: 1px solid #c5b58d;
    border-right-width: 0;
    bottom: 9em;
    display: none !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    max-height: 75vh;
    padding: 1.6875em 2.25em;
    position: fixed;
    right: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    width: 80vw;
    z-index: 100000;
  }
  .page_slide_in .title {
    flex-basis: 100%;
    -webkit-box-ordinal-group: 2;
            order: 1;
    padding-right: 1.125em;
    padding-top: 0;
  }
  .page_slide_in .image_wrapper {
    display: none;
    flex-basis: 33%;
    max-width: 10rem;
    -webkit-box-ordinal-group: 3;
            order: 2;
    padding-right: 1.125em;
    width: 33%;
  }
  .page_slide_in .image_wrapper img {
    max-width: 100%;
    max-height: 20vh;
    margin-right: auto;
    width: auto;
  }
  .page_slide_in .infotext {
    align-self: flex-start;
    -webkit-box-ordinal-group: 4;
            order: 3;
    line-height: 1.5em;
  }
  .page_slide_in .button {
    -webkit-box-ordinal-group: 5;
            order: 4;
    margin-bottom: 0;
    margin-top: 0;
  }
  .page_slide_in .close {
    padding: 0.75rem;
    position: absolute;
    right: 0;
    top: 0;
  }
  .page_slide_in .close svg {
    max-width: 2.1875rem;
    max-height: 2.1875rem;
  }
  .page_slide_in.show {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 3.375em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    font-weight: 400;
    min-width: 3.4375rem;
  }
  .address_vcard_button {
    margin: 0.75em 0;
  }
  .address_call_button {
    margin: 0.75em 0;
  }
  .atikon_logo {
    height: 100%;
  }
  [role="tablist"] {
    margin-bottom: 2.25em;
  }
  * + [role="tablist"] {
    margin-top: 2.25em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.5625em;
    min-width: 100%;
    padding: 0.75em 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 1.125em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #c5b58d;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #c5b58d;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #c5b58d;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #c5b58d;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 2.25em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .termin_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: center;
            align-items: center;
  }
  .author_wrapper {
    display: -webkit-box;
    display: flex;
    height: 0.5625rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    margin: 0 auto;
    max-width: 120rem;
    padding: 0.5rem 0.625rem;
    position: relative;
    margin-bottom: 1.875rem;
  }
  .author_hor {
    display: block;
    fill: #c5b58d;
    height: 0.5625rem;
    line-height: 0.5625rem;
    opacity: 0.6;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 4.6875rem;
  }
  .author_hor:focus,
  .author_hor:hover {
    opacity: 1;
  }
  .background_image_slider_overview {
    max-height: 100vh;
    position: relative;
    width: 100%;
    height: 21.875rem;
  }
  .background_image_slider_overview .background_image_wrapper {
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms ease-in-out;
    transition: opacity 600ms ease-in-out;
    width: 100%;
    z-index: 1;
  }
  .background_image_slider_overview .background_image_wrapper .background_image {
    height: 100%;
    position: absolute;
  }
  .background_image_slider_overview .background_image_wrapper.show {
    opacity: 1;
    pointer-events: auto;
  }
  .background_image_slider_overview .navigation_wrapper {
    bottom: 2.25em;
    display: -webkit-box;
    display: flex;
    position: absolute;
    right: 2.25em;
    z-index: 3333;
  }
  .background_image_slider_overview .navigation_wrapper .navigation {
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    width: 2.75rem;
  }
  .background_image_slider_overview .navigation_wrapper .navigation.prev .icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .background_image_slider_overview .navigation_wrapper .pagination {
    display: -webkit-box;
    display: flex;
  }
  .background_image_slider_overview .navigation_wrapper .pagination .item {
    background-color: rgba(255, 255, 255, 0.3);
    border: 0.1875rem solid #fff;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    margin-right: 2.25em;
    opacity: 0.5;
    width: 2.75rem;
  }
  .background_image_slider_overview .navigation_wrapper .pagination .item:last-child {
    margin-right: 0;
  }
  .background_image_slider_overview .navigation_wrapper .pagination .item.active {
    opacity: 1;
  }
  .background_image_slider_overview .navigation_wrapper > div {
    margin-right: 2.25em;
  }
  .background_image_slider_overview .navigation_wrapper > div:last-child {
    margin-right: 0;
  }
  .background_image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20rem;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .breadcrumbs {
    display: none;
  }
  .print_logo {
    display: none;
  }
  .company_logo_wrapper {
    height: 3.6875rem;
    max-width: 18.125rem;
    display: block;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    top: 1.25rem;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: top 300ms;
    transition: top 300ms;
    width: 100%;
    z-index: 1;
  }
  .company_logo {
    max-width: 18.125rem;
    bottom: 0;
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 2400ms, visibility 2400ms;
    transition: opacity 2400ms, visibility 2400ms;
    visibility: hidden;
  }
  .company_logo.active {
    opacity: 1;
    visibility: visible;
  }
  .company_logo_black_slogan {
    display: none;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #c5b58d;
    color: #202020;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.5625em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #202020;
    height: auto;
    left: 0.5625em;
    top: 0.5625em;
    width: auto;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #f4f4f4;
    border-top: 0.125rem solid #dfdfdf;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1.5em 1.125em 1.5em 2.25em;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin: 1.125em;
    padding: 0 1.125em;
  }
  .cookie_banner .close > span {
    min-height: 2rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.45em;
  }
  .download_overview {
    line-height: 1.75;
    margin: 2.25em 0 1.125em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin: 0;
    padding: 0;
  }
  .download_overview .touch_link .title {
    margin: 0;
  }
  .download_overview .touch_link small {
    opacity: 0.3;
  }
  .download_overview .icon {
    fill: #c5b58d;
    flex-shrink: 0;
    height: 1.25rem;
    margin: 0.25rem 0.75em 0 0;
    width: 1.25rem;
  }
  .content_slider_text {
    position: relative;
  }
  .content_slider_text .slide_wrapper {
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    position: relative;
  }
  .content_slider_text .slide_wrapper .slide {
    -webkit-box-flex: 1;
            flex: 1 0 100%;
    max-width: 100%;
    -webkit-transition: -webkit-transform 600ms;
    transition: -webkit-transform 600ms;
    transition: transform 600ms;
    transition: transform 600ms, -webkit-transform 600ms;
    z-index: 10;
  }
  .content_slider_text .slide_wrapper .slide .content_slider_slogan p {
    font-size: 210%;
  }
  .content_slider_text .navigation_wrapper {
    display: none !important;
    position: relative;
    z-index: 30;
  }
  .content_slider_text .dot {
    background-color: #fff;
    border: 0.125rem solid #fff;
    border-radius: 50%;
    cursor: pointer;
    height: 0.9375rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 0.9375rem;
  }
  .content_slider_text .dot_1 {
    background-color: #202020;
  }
  .content_slider_text .slide_prev,
  .content_slider_text .slide_next {
    display: none !important;
    background-color: rgba(197, 181, 141, 0.5);
    bottom: 0;
    cursor: pointer;
    display: block;
    opacity: 1;
    position: absolute;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 1.875rem;
    z-index: 20;
  }
  .content_slider_text .slide_prev.hidden,
  .content_slider_text .slide_next.hidden {
    cursor: default;
    opacity: 0;
  }
  .content_slider_text .slide_prev {
    left: 0;
  }
  .content_slider_text .slide_next {
    right: 0;
  }
  .content_slider_text .style_darker {
    text-shadow: 0px 0px 50px #000000;
  }
  .content_slider_text .style_darker p {
    font-weight: 900 !important;
  }
  .button {
    border-radius: 0;
    padding: 0 2.25em;
    background: #c5b58d;
    border: 0.0625rem solid #c5b58d;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .button .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 400;
    padding: 0.5625em 0;
    white-space: nowrap;
  }
  .button .icon + .text {
    padding-left: 0.75em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(197, 181, 141, 0.2);
    outline: 0;
    background: #c5b58d;
    border-color: #c5b58d;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #c5b58d;
    border-color: #c5b58d;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    background: #ab945a;
    border: 0.0625rem solid #ab945a;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 400;
    padding: 0.5625em 0;
    white-space: nowrap;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.75em;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul li {
    position: relative;
  }
  .dropdown_menu > ul li:hover > a:after,
  .dropdown_menu > ul li.hover > a:after {
    opacity: 1;
    width: 100%;
  }
  .dropdown_menu > ul li:hover > ul,
  .dropdown_menu > ul li.hover > ul {
    margin-top: 1.6875em;
    opacity: 1;
    visibility: visible;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
    font-size: 0.875rem;
    letter-spacing: -0.03125rem;
    margin-left: 0.625rem;
  }
  .dropdown_menu > ul > li:nth-child(2) {
    margin-right: 21.875rem;
  }
  .dropdown_menu > ul > li:first-child,
  .dropdown_menu > ul > li:nth-child(5) {
    margin-left: 0;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    font-size: 125%;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    outline: 0;
    position: relative;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a.active:after,
  .dropdown_menu > ul > li > a:focus:after {
    opacity: 1;
    width: 100%;
  }
  .dropdown_menu > ul > li > a:after {
    background: #c5b58d;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: opacity 300ms, width 300ms;
    transition: opacity 300ms, width 300ms;
    width: 0;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .dropdown_menu > ul > li ul {
    background: rgba(255, 255, 255, 0.8);
    -webkit-box-pack: center;
            justify-content: center;
    left: 50%;
    margin-top: 0.5625em;
    opacity: 0;
    padding: 1.125em 1.5em;
    position: absolute;
    text-align: center;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
    z-index: 1000;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .dropdown_menu > ul > li ul:target {
    margin-top: 1.6875em;
    opacity: 1;
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #202020;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    -webkit-box-pack: center;
            justify-content: center;
    outline: 0;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li ul a:after {
    background: #c5b58d;
    bottom: 0.25rem;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: opacity 300ms, width 300ms;
    transition: opacity 300ms, width 300ms;
    width: 0;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .dropdown_menu > ul > li ul a.active:after,
  .dropdown_menu > ul > li ul a:focus:after,
  .dropdown_menu > ul > li ul a:hover:after {
    opacity: 1;
    width: 100%;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  header.scrolled_down .dropdown_menu > ul li ul {
    background: rgba(255, 255, 255, 0.94);
  }
  .footer_logo {
    max-width: 17.375rem;
    display: block;
    margin: 0 auto 2.25em auto;
    width: 100%;
  }
  footer {
    line-height: 1.75;
  }
  footer .section_w_logo {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  footer .address,
  footer .centered {
    text-align: center;
  }
  footer .contact_wrapper {
    display: block;
    text-align: center;
  }
  footer .contact_wrapper p {
    margin-right: 0;
  }
  footer .contact_wrapper .postal_address > span {
    display: inline-block;
  }
  .route-facebook {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 2.25em;
  }
  .route-facebook .button {
    margin-right: 0.9375rem;
  }
  .route-facebook .facebook {
    display: block;
    height: 2.75rem;
    width: 2.75rem;
  }
  .ctas {
    margin: 1.125em auto 0 auto;
  }
  .cta {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .cta .icon {
    display: block;
    fill: #c5b58d;
    margin-bottom: 1.125em;
    height: 2.1875rem;
    margin-right: 1.40625rem;
    width: 2.1875rem;
  }
  .cta a {
    color: #202020;
    text-decoration: underline;
  }
  .cta a:focus,
  .cta a:hover {
    color: #c5b58d;
  }
  .related_links {
    border-top: 0.0625rem solid #c5b58d;
    margin-top: 2.25em;
    padding: 1.5em 0;
  }
  .related_links a {
    color: #202020;
  }
  .related_links a:focus,
  .related_links a:hover {
    color: #c5b58d;
  }
  .related_links ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .related_links ul li {
    padding-right: 0.9375rem;
  }
  .related_links ul li:after {
    color: #c5b58d;
    content: "|";
    padding-left: 0.625rem;
  }
  .related_links ul li:last-child:after {
    content: "" !important;
  }
  .footer_logo_center {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .footer_logo_center img {
    max-width: 10.9375rem;
  }
  .footer_logo_left,
  .footer_logo_right {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .footer_logo_left img,
  .footer_logo_right img {
    max-width: 17.1875rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  header,
  .image_wrapper {
    -webkit-animation: fadein 900ms forwards;
            animation: fadein 900ms forwards;
    opacity: 0;
  }
  .image_wrapper {
    position: relative;
  }
  header {
    background: rgba(32, 32, 32, 0);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    z-index: 100;
    height: 9.375rem;
  }
  header .container {
    position: relative;
  }
  .functions_wrapper {
    top: 6.25rem;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .functions {
    display: block;
    fill: #fff;
    height: 2rem;
    line-height: 0;
    margin: 0;
    padding: 0;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2rem;
  }
  .functions svg {
    display: block;
  }
  .functions.icon_call {
    height: 1.5rem;
    margin-left: 1.5rem;
    width: 1.5rem;
  }
  .functions:focus,
  .functions:hover {
    fill: #c5b58d;
  }
  .header_content {
    -webkit-animation: fadein 2400ms 3000ms forwards;
            animation: fadein 2400ms 3000ms forwards;
    bottom: 0;
    display: none;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .header_content .inner p {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    word-wrap: break-word;
    letter-spacing: calc((1.125rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.125rem;
    color: #000000;
    font-family: "Playfair Display", sans-serif;
    font-weight: 400;
    line-height: 1.35;
    margin: 0 0 0.75em;
    padding-top: 0.75em;
    color: #fff;
    line-height: 1.785;
    margin-bottom: 1.125em;
    max-width: 50rem;
  }
  .header_content .btn {
    margin-right: 1.125em;
  }
  .image_gallery_overview {
    margin: 2.25em 0;
    position: relative;
  }
  .image_gallery_entry {
    background-color: #f4f4f4;
    display: block;
    margin-bottom: 2.25em;
    position: relative;
  }
  .image_gallery_entry:focus,
  .image_gallery_entry:hover {
    outline: 0;
  }
  .image_gallery_entry:focus .open,
  .image_gallery_entry:hover .open {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_entry .open {
    background: rgba(32, 32, 32, 0.8);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
  }
  .image_gallery_entry .open svg {
    height: 3.75rem;
    margin: auto;
    width: 3.75rem;
  }
  .image_gallery_entry .image {
    background-position: 0 90%;
    background-size: cover;
    height: 0;
    overflow: hidden;
    padding-bottom: 62.5%;
  }
  .image_gallery_overlay {
    background: rgba(32, 32, 32, 0.8);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
  }
  .image_gallery_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .slider {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    white-space: nowrap;
    width: 100%;
  }
  .image_gallery_overlay .slider.bounce_from_right {
    -webkit-animation: image_gallery_bounce_from_right 300ms ease-out;
            animation: image_gallery_bounce_from_right 300ms ease-out;
  }
  .image_gallery_overlay .slider.bounce_from_left {
    -webkit-animation: image_gallery_bounce_from_left 300ms ease-out;
            animation: image_gallery_bounce_from_left 300ms ease-out;
  }
  .image_gallery_overlay .full_image {
    display: inline-block;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .image_gallery_overlay .full_image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 0;
  }
  .image_gallery_overlay .full_image figure {
    display: inline;
    height: 100%;
    margin: 0;
  }
  .image_gallery_overlay .full_image figcaption {
    background: #c5b58d;
    bottom: 0;
    color: #202020;
    display: block;
    padding: 1.125em;
    position: absolute;
    text-align: center;
    white-space: normal;
    width: 100%;
  }
  .image_gallery_overlay .full_image img {
    box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.2);
    display: inline-block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    vertical-align: middle;
    visibility: hidden;
    width: auto;
  }
  .image_gallery_overlay .full_image img.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .spinner {
    display: inline-block;
    height: 1.125rem;
    left: 50%;
    margin-left: -2.1875rem;
    margin-top: -0.5625rem;
    position: absolute;
    top: 50%;
    width: 4.375rem;
  }
  .image_gallery_overlay .spinner .bounce {
    -webkit-animation: image_gallery_spinner 900ms infinite;
            animation: image_gallery_spinner 900ms infinite;
    background: #c5b58d;
    border-radius: 100%;
    display: inline-block;
    height: 1.125rem;
    margin: 0 0.25rem;
    width: 1.125rem;
  }
  .image_gallery_overlay .spinner .bounce_1 {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
  }
  .image_gallery_overlay .spinner .bounce_2 {
    -webkit-animation-delay: -150ms;
            animation-delay: -150ms;
  }
  .image_gallery_overlay nav a {
    cursor: pointer;
    display: block;
    position: absolute;
  }
  .image_gallery_overlay nav .previous,
  .image_gallery_overlay nav .next {
    height: 2.75rem;
    margin-top: -1.375rem;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous:focus .icon,
  .image_gallery_overlay nav .next:focus .icon,
  .image_gallery_overlay nav .previous:hover .icon,
  .image_gallery_overlay nav .next:hover .icon {
    fill: #c5b58d;
  }
  .image_gallery_overlay nav .previous .icon,
  .image_gallery_overlay nav .next .icon {
    fill: #fff;
    height: 2.75rem;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous {
    left: 2%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .image_gallery_overlay nav .next {
    right: 2%;
  }
  .image_gallery_overlay nav .close {
    background: #fff;
    height: 2.75rem;
    padding: 0.375rem;
    right: 0;
    top: 0;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .close:focus,
  .image_gallery_overlay nav .close:hover {
    outline: 0;
  }
  .image_gallery_overlay nav .close:focus .icon,
  .image_gallery_overlay nav .close:hover .icon {
    fill: #c5b58d;
  }
  .image_gallery_overlay nav .close .icon {
    fill: #202020;
    height: 2rem;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 2rem;
  }
  .google_maps {
    margin: 0 0 1.125em;
  }
  .google_maps .map {
    background: #f4f4f4;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 1.125em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #c5b58d;
    border-color: #c5b58d;
    color: #000000;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #000000;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(197, 181, 141, 0.2);
    z-index: 15;
    background: #c5b58d;
    border-color: #c5b58d;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #dfdfdf;
    color: #202020;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #202020;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 400;
    padding: 0.5625em 0;
    white-space: nowrap;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.75em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #202020;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #c5b58d;
    font-size: 85%;
    margin-bottom: 1.125em;
    padding: 1.5em 2.25em;
  }
  .google_maps .direction {
    margin: 1.125em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 1.125em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #dfdfdf;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #dfdfdf;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 1.125em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 1.125em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 1.125em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 1.125em;
  }
  .form_disclaimer {
    background: #f4f4f4;
    border-radius: 0;
    color: #202020;
    margin-bottom: 1.125em;
    padding: 1.5em 2.25em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    height: 2.75rem;
    margin: 0 0 1.5em;
    position: relative;
  }
  .file.error .file_name {
    border-color: #c5b58d;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #dfdfdf;
    border-radius: 0 0 0 0;
    color: #202020;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    height: 2.75rem;
    line-height: calc(2.75rem - (0.0625rem * 2));
    overflow: hidden;
    padding: 0 1.125em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 2.25em;
    z-index: 10;
    background: #c5b58d;
    border: 0.0625rem solid #c5b58d;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .file .file_button .icon {
    fill: #000000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 400;
    padding: 0.5625em 0;
    white-space: nowrap;
  }
  .file .file_button .icon + .text {
    padding-left: 0.75em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(197, 181, 141, 0.2);
    background: #c5b58d;
    border-color: #c5b58d;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 2.75rem;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #c5b58d;
    border-color: #c5b58d;
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #ab945a inset;
    background: #ab945a;
    border: 0.0625rem solid #ab945a;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 400;
    padding: 0.5625em 0;
    white-space: nowrap;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.75em;
  }
  button {
    cursor: pointer;
    margin: 1.125em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 2.25em;
    width: 100%;
    height: 2.75rem;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .header_background {
    height: 21.875rem;
    background-position: center center;
    background-size: cover;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 0 !important;
  }
  .startpage .header_background {
    max-height: 100vh;
  }
  .startpage .video_wrapper {
    height: 0;
    margin-bottom: 2.25em;
    padding-top: 0;
    position: relative;
  }
  .wrap-anim {
    background: #f4f4f4;
    position: relative;
  }
  .wrap-anim .header_anim video {
    height: 100%;
    left: 50%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
  }
  .wrap-anim .wrap-box {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .wrap-anim .wrap-box .container {
    height: 100%;
  }
  .wrap-anim .wrap-box .wrap-flex {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 100%;
  }
  .wrap-anim .wrap-box .box {
    background: rgba(244, 244, 244, 0.7);
    text-align: right;
    @include scale(padding-bottom, 2.25em, 4.5em);
    @include scale(padding-left, 2.25em, 6.75em);
    @include scale(padding-right, 2.25em, 6.75em);
    @include scale(padding-top, 2.25em, 4.5em);
  }
  .wrap-anim .wrap-box .box .style_h1,
  .wrap-anim .wrap-box .box p {
    text-align: left;
  }
  .wrap-anim .wrap-box .box .style_h1 {
    @include font-size(560rem, 1280rem, 0);
    font-weight: 400;
    line-height: 1.15;
    margin-bottom: 0.225em;
  }
  .wrap-anim .wrap-box .box p:not(.style_h1) {
    margin-bottom: 2.8125em;
    max-width: 30rem;
  }
  body.startpage .wrap-anim .header_anim {
    height: 40vh;
    margin-top: 0 !important;
    min-height: 20rem;
  }
  body:not(.startpage) .wrap-anim .header_anim .wrap-video {
    @include scale(height, 23.75rem, 37.5rem);
  }
  .container.content {
    max-width: 81.25rem;
  }
  main {
    margin-bottom: 3.125rem;
  }
  .overview {
    line-height: 1.75;
    margin: 2.25em 0 1.125em;
  }
  .overview .touch_link {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.125em;
  }
  .overview .touch_link .title_wrapper {
    margin: 0;
    padding: 0;
  }
  .overview .touch_link .title {
    margin: 0;
  }
  .overview .touch_link .text {
    display: block;
    font-weight: 400;
  }
  .overview .icon {
    fill: #c5b58d;
    flex-shrink: 0;
    height: 1.25rem;
    margin: 0.25rem 0.75em 0 0;
    width: 1.25rem;
  }
  .destillate_download {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  body.newspage .folder_content .entry {
    border-bottom: 0.0625rem solid #c5b58d;
  }
  body.newspage .folder_content .entry:after {
    clear: both;
  }
  body.newspage .folder_content .entry:after,
  body.newspage .folder_content .entry:before {
    content: "";
    display: table;
  }
  body.newspage .folder_content img {
    display: none;
  }
  body.startpage h1 {
    text-transform: uppercase;
  }
  body.h1_nomargin h1 {
    margin: 0;
  }
  .subline {
    margin: 0 auto 2.8125em auto;
    max-width: 47.5rem;
    padding: 0 0.9375rem;
    text-align: center;
  }
  .subline p {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    word-wrap: break-word;
    letter-spacing: calc((1.125rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.125rem;
    color: #000000;
    font-family: "Playfair Display", sans-serif;
    font-weight: 400;
    line-height: 1.35;
    margin: 0 0 0.75em;
    padding-top: 0.75em;
    display: inline-block;
    margin: 0;
    padding: 0;
    position: relative;
  }
  .subline p:after,
  .subline p:before {
    background-image: url("../images/star.svg");
    background-position: center center;
    background-size: 0.9375rem 0.9375rem;
    content: "";
    display: block;
    height: 0.9375rem;
    margin-top: -0.375rem;
    position: absolute;
    top: 50%;
    width: 0.9375rem;
  }
  .subline p:before {
    left: -1.25rem;
  }
  .subline p:after {
    right: -1.25rem;
  }
  .subline.dark p:after,
  .subline.dark p:before {
    background-image: url("../images/star_dark.svg");
  }
  .aida_buchung {
    border: 0.0625rem solid #dfdfdf;
    height: 56.25rem;
    max-width: 40.625rem;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 10;
  }
  .aida_buchung iframe {
    border: 0;
    height: 312.5rem;
    width: 37.5rem;
  }
  .info_closed {
    font-family: "Playfair Display", sans-serif;
    font-weight: 700;
    font-size: 115%;
  }
  .rm-open-popup {
    font-family: "Playfair Display", sans-serif;
    position: fixed;
    bottom: 25px;
    right: 0;
    -webkit-border-top-left-radius: 0.5rem;
    -webkit-border-bottom-left-radius: 0.5rem;
    -moz-border-radius-topleft: 0.5rem;
    -moz-border-radius-bottomleft: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .rm-open-popup .text {
    font-weight: 700;
  }
  #rmOrganism .rmSubscription .rmBase__comp--heading {
    font-family: "Playfair Display", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.5625rem;
    margin-left: 1.5rem;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 2rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #fff;
    display: block;
    height: 0.1875rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.6875rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(32, 32, 32, 0.8);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 2.25em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0.5625em 2.25em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #202020;
    display: block;
    font-size: 125%;
    line-height: 1.2;
    text-transform: uppercase;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    color: #c5b58d;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul li:first-child {
    padding-top: 0.5625em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul li:last-child {
    padding-bottom: 1.125em;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #202020;
    display: block;
    text-transform: uppercase;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #c5b58d;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: block !important;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 600ms;
    transition: height 600ms;
  }
  .open_street_map .map {
    height: 21.875rem;
    background-color: #f4f4f4;
    cursor: move;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(244, 244, 244, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 2.25em 4.5em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 2.25em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 2.25em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 2.25em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 1.125em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 2.25em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #c5b58d;
    border: 0.0625rem solid #c5b58d;
    border-radius: 0;
    color: #000000;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #c5b58d;
    border: 0.0625rem solid #c5b58d;
    box-shadow: 0 0 0 0.1875rem rgba(197, 181, 141, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #c5b58d;
    border: 0.0625rem solid #c5b58d;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.875rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 1.125em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #f4f4f4;
    border-radius: 0;
    color: #202020;
    margin-bottom: 1.125em;
    padding: 1.5em 2.25em;
  }
  .opening_times .title {
    padding-top: 1.125em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 3.375em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 1800ms linear infinite;
            animation: preloader_rotate 1800ms linear infinite;
    display: block;
    fill: #dfdfdf;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .steuernews_preview {
    margin-bottom: 1.125em;
  }
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 2.25em;
  }
  .steuernews_preview .image {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    line-height: 0;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .steuernews_preview .image img {
    margin-right: 2.25em;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .title {
    word-wrap: break-word;
  }
  .news_entity .hide_image_author,
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    float: right;
    margin-left: 2.25em;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
  }
  .steuernews_issues ul li {
    padding-left: 0;
  }
  .steuernews_issues a {
    background: #f4f4f4;
    border-radius: 0;
    display: inline-block;
    margin: 0 1.125em 1.125em 0;
    padding: 0 2.25em;
    white-space: nowrap;
    line-height: 2.75rem;
  }
  .steuernews_disclaimer {
    clear: both;
  }
  .steuernews_sidebar {
    margin: 2.25em 0 1.125em;
  }
  .steuernews_current_issue {
    margin-bottom: 1.125em;
  }
  .highlight_keyword {
    background: #c5b58d;
    color: #fff;
    outline: 0.125rem solid #c5b58d;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 1.125em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 1.125em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.5625em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(32, 32, 32, 0.8);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 2.25em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #c5b58d;
    border-radius: 0 0 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.64285714em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.2);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .steuernews_issues_select {
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.125em;
    max-width: 30rem;
    width: 100%;
  }
  .steuernews_issues_select .select {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 0;
  }
  .steuernews_issues_select .button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    flex-shrink: 0;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 1.125em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
    font-weight: bold !important;
    text-transform: uppercase;
    font-size: 1.5rem;
  }
  .team_overview .photo {
    display: -webkit-box;
    display: flex;
  }
  .team_overview .photo img {
    display: block;
    border-radius: 35em;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 1.125em;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    text-align: center;
  }
  .team_overview .info h2 {
    font-weight: 400;
  }
  .team_overview .info .span_column {
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .team_entry .photo {
    margin-bottom: 1.125em;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 1.125em;
  }
  .mobile_only {
    display: none;
    width: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
  }
  .urlaubsangebote {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #c5b58d;
    border: 0.0625rem solid #c5b58d;
    border-radius: 0;
    bottom: 100%;
    color: #202020;
    font-size: 85%;
    font-weight: 400;
    line-height: 2.25;
    opacity: 1;
    padding: 0.375em 1.125em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #c5b58d;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #c5b58d;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    opacity: 0;
    -webkit-transform: translateY(-0.9375rem);
            transform: translateY(-0.9375rem);
    visibility: hidden;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 2.25em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper .overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: #f4f4f4;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 2.25em;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: 1;
  }
  .video_wrapper .overlay .text_wrapper a {
    display: block;
  }
  .video_wrapper.show_message {
    min-height: 18.75rem;
  }
  .video_wrapper.show_message .overlay {
    opacity: 1;
    visibility: visible;
  }
  .video_wrapper .text_wrapper {
    font-size: 85%;
    line-height: normal;
    overflow-y: auto;
    width: 100%;
  }
  .video_wrapper .button {
    margin-top: 2.25em;
  }
}
@media screen and (max-width: 34rem) {
  .event_overview .event .title {
    padding-top: 1.125em;
  }
}
@media screen and (min-width: 20rem) {
  .formular_short {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 61.875rem) {
  .formular_short {
    background: none;
    padding: 0;
  }
  .formular_short:before {
    background: #c5b58d;
    bottom: 2.25em;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 2.25em;
    z-index: -1;
  }
}
@media screen and (min-width: 75rem) {
  .formular_short {
    margin: 3.375em 0 5.625em;
  }
}
@media screen and (min-width: 61.875rem) {
  .formular_short .img_wrapper {
    display: block;
    line-height: 0;
  }
  .formular_short .img_wrapper img {
    margin-left: 2.25em;
  }
}
@media screen and (min-width: 35rem) {
  .formular_short fieldset .inner {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 35rem) {
  .formular_short fieldset .checkbox {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 20rem) {
  .formular_short .button > span {
    min-height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .formular_short .button > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .formular_short .button:active > span,
  .formular_short .button.active > span {
    min-height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .formular_short .button:active > span,
  .formular_short .button.active > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 75rem) {
  .img_with_text {
    margin: 3.375em 0 5.625em;
  }
}
@media screen and (min-width: 75rem) {
  .img_with_text .title_wrapper {
    margin-bottom: 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .img_with_text .img_wrapper {
    margin-left: calc(-0.9375rem + -2.1875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .img_with_text .img_wrapper {
    margin-left: -3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .img_with_text .img_wrapper {
    margin-right: calc(-0.9375rem + -2.1875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .img_with_text .img_wrapper {
    margin-right: -3.125rem;
  }
}
@media screen and (min-width: 58.4375rem) {
  .img_with_text .img_wrapper {
    margin: 0 auto;
  }
}
@media screen and (min-width: 75rem) {
  .img_with_text .img_wrapper {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: calc(-50vw + 100%);
  }
  .column_30_30 .img_with_text .img_wrapper {
    margin-right: calc(-40vw + 140%);
  }
}
@media screen and (min-width: 75rem) {
  .img_with_text.reverse .img_wrapper {
    margin-left: calc(-50vw + 100%);
    margin-right: 0;
    text-align: right;
  }
  .column_30_30 .img_with_text.reverse .img_wrapper {
    margin-left: calc(-40vw + 140%);
  }
}
@media screen and (min-width: 75rem) {
  .img_with_text:not(.reverse) .order2_xsmall {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
}
@media screen and (min-width: 75rem) {
  .img_with_text.dark {
    padding: 4.5em 0;
  }
}
@media screen and (min-width: 20rem) {
  .img_with_text.dark .button > span {
    min-height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .img_with_text.dark .button > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 75rem) {
  .img_with_text.dark .button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  .img_with_text.dark .button:active > span,
  .img_with_text.dark .button.active > span {
    min-height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .img_with_text.dark .button:active > span,
  .img_with_text.dark .button.active > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 75rem) {
  .img_with_text.dark .img_wrapper {
    margin: 0;
    padding: 0;
    text-align: auto;
  }
}
@media screen and (min-width: 30rem) {
  .page_slide_in {
    width: 71vw;
  }
}
@media screen and (min-width: 35rem) {
  .page_slide_in {
    width: 62vw;
    display: -webkit-box !important;
    display: flex !important;
  }
}
@media screen and (min-width: 47.5rem) {
  .page_slide_in {
    width: 56vw;
  }
}
@media screen and (min-width: 47.5rem) {
  .page_slide_in {
    width: 56vw;
  }
}
@media screen and (min-width: 61.875rem) {
  .page_slide_in {
    width: 43vw;
  }
}
@media screen and (min-width: 75rem) {
  .page_slide_in {
    width: 31vw;
  }
}
@media screen and (min-width: 93.75rem) {
  .page_slide_in {
    width: 25vw;
  }
}
@media screen and (min-width: 1800px) {
  .page_slide_in {
    width: 20vw;
  }
}
@media screen and (orientation: landscape) and (max-width: 35rem) {
  .page_slide_in {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    bottom: 0;
    display: block;
    left: 0;
    width: 100%!important;
  }
  .page_slide_in .image_wrapper {
    float: left;
    padding-right: 1.125em;
    width: 20%;
  }
  .page_slide_in .image_wrapper img {
    max-height: 20vh;
    width: auto;
  }
  .page_slide_in .title,
  .page_slide_in .infotext {
    width: 100%!important;
    line-height: 1.5em;
  }
  .page_slide_in .button {
    clear: both;
  }
}
@media screen and (min-width: 20rem) {
  .page_slide_in .image_wrapper {
    display: block;
  }
}
@media screen and (min-width: 20rem) {
  .page_slide_in .infotext {
    flex-basis: 67%;
    width: 67%;
  }
}
@media screen and (min-width: 20rem) {
  .contact strong {
    min-width: calc(3.4375rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .contact strong {
    min-width: 4.0625rem;
  }
}
@media screen and (min-width: 75rem) {
  .termin_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}
@media screen and (min-width: 75rem) {
  .termin_wrapper .img_wrapper {
    margin-left: 2.25em;
    margin-top: 0.5625em;
  }
}
@media screen and (min-width: 20rem) {
  .author_wrapper {
    margin-bottom: calc(1.875rem + 2.1875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .author_wrapper {
    margin-bottom: 4.0625rem;
  }
}
@media screen and (min-width: 20rem) {
  .background_image_slider_overview {
    height: calc(21.875rem + 28.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .background_image_slider_overview {
    height: 50rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .background_image_slider_overview {
    height: 21.875rem;
  }
}
@media screen and (min-width: 93.75rem) and (min-width: 20rem) {
  .background_image_slider_overview {
    height: calc(21.875rem + 71.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) and (min-width: 93.75rem) {
  .background_image_slider_overview {
    height: 93.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo_wrapper {
    height: calc(3.6875rem + 2.3125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo_wrapper {
    height: 6rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo_wrapper {
    max-width: calc(18.125rem + -0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo_wrapper {
    max-width: 17.5rem;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo_wrapper {
    left: auto;
    top: 2.1875rem;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@media screen and (min-width: 100rem) {
  .company_logo_wrapper {
    left: 50%;
    top: 3.125rem;
    -webkit-transform: translateX(-38%);
            transform: translateX(-38%);
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    max-width: calc(18.125rem + -0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    max-width: 17.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .button > span {
    min-height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active > span,
  .button.active > span {
    min-height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active > span,
  .button.active > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 100rem) {
  .dropdown_menu {
    display: block;
    left: 50%;
    position: absolute;
    top: 4.1875rem;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: top 300ms;
    transition: top 300ms;
  }
}
@media screen and (min-width: 96.875rem) {
  .dropdown_menu {
    top: 4.6875rem;
  }
}
@media screen and (min-width: 100rem) {
  .dropdown_menu {
    top: 3.75rem;
  }
}
@media screen and (min-width: 100rem) {
  .dropdown_menu > ul > li {
    margin-left: 1.0625rem;
  }
  .dropdown_menu > ul > li:nth-child(2) {
    margin-right: 20.625rem;
  }
}
@media screen and (min-width: 106.25rem) {
  .dropdown_menu > ul > li {
    margin-left: 2.375rem;
  }
}
@media screen and (min-width: 100rem) {
  header.scrolled_down .dropdown_menu {
    top: 1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .footer_logo {
    max-width: calc(17.375rem + 11.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .footer_logo {
    max-width: 29rem;
  }
}
@media screen and (min-width: 61.875rem) {
  footer p {
    margin-bottom: 2.25em;
  }
}
@media screen and (min-width: 61.875rem) {
  .route-facebook {
    margin: 0;
  }
}
@media screen and (min-width: 20rem) {
  .route-facebook .facebook {
    height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .route-facebook .facebook {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .route-facebook .facebook {
    width: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .route-facebook .facebook {
    width: 2.75rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .ctas {
    margin-top: 0;
  }
}
@media screen and (min-width: 20rem) {
  .cta .icon {
    height: calc(2.1875rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .cta .icon {
    height: 2.8125rem;
  }
}
@media screen and (min-width: 20rem) {
  .cta .icon {
    margin-right: calc(1.40625rem + 0.46875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .cta .icon {
    margin-right: 1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .cta .icon {
    width: calc(2.1875rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .cta .icon {
    width: 2.8125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .cta .icon {
    margin-bottom: 2.25em;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 20rem) {
  header {
    height: calc(9.375rem + 4.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  header {
    height: 13.75rem;
  }
}
@media screen and (min-width: 35rem) {
  header {
    height: 7.5rem;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  header {
    height: calc(7.5rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  header {
    height: 8.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  header {
    position: fixed;
  }
}
@media screen and (min-width: 100rem) {
  header {
    height: 9.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  header.scrolled_down {
    background: rgba(255, 255, 255, 0.95);
  }
  header.scrolled_down .icon {
    fill: #202020;
  }
  header.scrolled_down .icon .line {
    background: #202020;
  }
  header.scrolled_down .dropdown_menu ul li a {
    color: #202020;
  }
  header.scrolled_down .company_logo_black_slogan {
    display: block;
  }
  header.scrolled_down .company_logo_slogan {
    display: none;
  }
}
@media screen and (min-width: 100rem) {
  header.scrolled_down {
    height: 6.25rem;
  }
  header.scrolled_down .company_logo_wrapper {
    top: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .functions_wrapper {
    top: calc(6.25rem + 3.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .functions_wrapper {
    top: 10rem;
  }
}
@media screen and (min-width: 35rem) {
  .functions_wrapper {
    top: 2.6875rem;
    left: auto;
    right: calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  .functions_wrapper {
    top: calc(2.6875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  .functions_wrapper {
    top: 2.9375rem;
  }
}
@media screen and (min-width: 100rem) {
  .functions_wrapper {
    display: none;
  }
}
@media screen and (min-width: 100rem) {
  .header_content {
    display: block;
  }
}
@media screen and (min-width: 100rem) {
  .header_content .inner {
    padding-top: 12.5rem;
  }
}
@media screen and (min-width: 96.875rem) {
  .header_content .inner {
    padding-top: 13.4375rem;
  }
}
@media screen and (min-width: 100rem) {
  .header_content .inner {
    padding-top: 14.375rem;
  }
}
@media screen and (min-width: 106.25rem) {
  .header_content .inner {
    padding-top: 15rem;
  }
}
@media screen and (min-width: 20rem) {
  .header_content .inner p {
    font-size: calc(1.125rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_content .inner p {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_entry {
    height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_entry {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_radio > span {
    min-height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_radio > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button > span {
    min-height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: 2.75rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 20rem) {
  button {
    height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  button {
    height: 2.75rem;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .header_background {
    height: calc(21.875rem + 28.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_background {
    height: 50rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .startpage .header_background {
    height: 21.875rem;
  }
}
@media screen and (min-width: 93.75rem) and (min-width: 20rem) {
  .startpage .header_background {
    height: calc(21.875rem + 71.875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) and (min-width: 93.75rem) {
  .startpage .header_background {
    height: 93.75rem;
  }
}
@media screen and (min-width: 35rem) {
  body.startpage .wrap-anim .header_anim {
    height: 60vh;
  }
}
@media screen and (min-width: 61.875rem) {
  body.startpage .wrap-anim .header_anim {
    height: 70vh;
  }
}
@media screen and (min-width: 20rem) {
  main {
    margin-bottom: calc(3.125rem + 3.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  main {
    margin-bottom: 6.875rem;
  }
}
@media screen and (min-width: 35rem) {
  body.newspage .folder_content img {
    border-color: #fff;
    border-style: solid;
    box-shadow: 0 0.1875rem 0.4375rem rgba(0, 0, 0, 0.25);
    display: block;
    -webkit-transform: rotate(1.5deg);
            transform: rotate(1.5deg);
    border-width: 0.25rem;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  body.newspage .folder_content img {
    border-width: calc(0.25rem + 0.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  body.newspage .folder_content img {
    border-width: 0.625rem;
  }
}
@media screen and (min-width: 75rem) {
  .subline {
    padding: 0;
  }
}
@media screen and (min-width: 20rem) {
  .subline p {
    font-size: calc(1.125rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .subline p {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .subline p:before {
    left: calc(-1.25rem + -0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .subline p:before {
    left: -1.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .subline p:after {
    right: calc(-1.25rem + -0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .subline p:after {
    right: -1.875rem;
  }
}
@media screen and (min-width: 100rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 99.9375rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_1 {
    top: 0.6875rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_3 {
    bottom: 0.6875rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .open_street_map .map {
    height: calc(21.875rem + 12.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 2.25em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 2.25em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_issues a {
    line-height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_issues a {
    line-height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    height: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    width: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 2.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_content .content {
    top: calc(2.75rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 2.75rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry {
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (max-width: 93.75rem) {
  .desktop_only {
    display: none !important;
  }
  .mobile_only {
    display: -webkit-box;
    display: flex;
  }
  .mobile_only.star,
  .mobile_only .star {
    margin-bottom: 1.3em;
    display: -webkit-box;
    display: flex;
  }
}
@media print {
  .print_logo {
    display: block;
    margin: 0 auto;
    max-width: 18.125rem;
  }
  .company_logo {
    display: none;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  body.startpage h1 {
    margin: 0;
    text-transform: uppercase;
  }
  h1,
  .subline {
    text-align: center;
  }
  .subline p {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    word-wrap: break-word;
    letter-spacing: calc((1.125rem + 0.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.125rem;
    color: #000000;
    font-family: "Playfair Display", sans-serif;
    font-weight: 400;
    line-height: 1.35;
    margin: 0 0 0.75em;
    padding-top: 0.75em;
    margin: 0;
    padding: 0;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 2.25em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 1.125em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 2.25em;
  }
  .subline {
    margin: 0 auto 2.8125em auto;
    padding: 0;
    text-align: center;
  }
  .subline h3 {
    margin: 0;
    padding: 0;
  }
  .img_with_text .title_wrapper {
    margin-bottom: 1.125em;
  }
  .img_with_text h2,
  .img_with_text h3 {
    text-align: center;
  }
  .img_with_text h2 {
    margin: 0 0 0.25rem 0;
  }
  .img_with_text .subline,
  .img_with_text .text {
    margin: 0 auto;
    width: 100%;
  }
  .img_with_text .text {
    text-align: center;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 2.25em 0 0;
    padding: 1.125em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
@media print and (min-width: 20rem) {
  .subline p {
    font-size: calc(1.125rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media print and (min-width: 93.75rem) {
  .subline p {
    font-size: 1.75rem;
  }
}
